import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";

const StickyProvider = (props) => {
    const provider = props.data;

    const handleScroll = (event) => {
        let element = document.querySelector('#provider-card-wrapper'),
            stickyBar = document.querySelector('.sticky-provider-row');

        if (element) {
            if (isElementNotInViewPort(element)) {
                stickyBar.classList.add('sticky-provider-row-visible');
            } else {
                stickyBar.classList.remove('sticky-provider-row-visible');
            }
        }
    }

    const isElementNotInViewPort = (element) => {
        const currentScrollPos = window.pageYOffset;
        let contentBottom = element.offsetTop + element.offsetHeight;

        return currentScrollPos >= contentBottom;
    }

    const handleScrollBind = handleScroll.bind(this);

    useEffect(() => {
        window.addEventListener('scroll', handleScrollBind);

        return () => {
            window.removeEventListener('scroll', handleScrollBind);
        };
    });

    return (
        <div className="sticky-provider-row">
            <Container>
                <div className="sticky-provider-wrapper row">
                <div className="sticky-provider-image-wrapper col-3">
                    <a href={provider.site} className="sticky-provider-link" target="_blank">
                        <img
                            data-src={provider.image}
                            className="sticky-provider-image lazyload"
                            alt={provider.provider}
                        />
                        <svg className="sticky-provider-link-icon">
                            <use xlinkHref="/images/chevron-dark.svg#chevron_dark"/>
                        </svg>
                    </a>
                </div>
                <div className="sticky-provider-speed-wrapper col-3">
                    <svg className="sticky-provider-speed-icon">
                        <use xlinkHref="/images/provider-info.svg#speed"/>
                    </svg>
                    <span className="sticky-provider-speed-text">
                            SPEED — {provider.speed}ms
                        </span>
                </div>
                <div className="sticky-provider-uptime-wrapper col-3">
                    <svg className="sticky-provider-uptime-icon">
                        <use xlinkHref="/images/provider-info.svg#uptime"/>
                    </svg>
                    <span className="sticky-provider-uptime-text">UPTIME — {provider.live_uptime}%</span>
                </div>
                <div className="sticky-provider-pricing-wrapper col-3">
                    <svg className="sticky-provider-pricing-icon">
                        <use xlinkHref="/images/provider-info.svg#price"/>
                    </svg>
                    <span className="sticky-provider-pricing-text">PRICING — {provider.pricing}/10</span>
                </div>
            </div>
            </Container>
        </div>
    );
};

StickyProvider.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        provider: PropTypes.string.isRequired,
        speed: PropTypes.string.isRequired,
        live_uptime: PropTypes.string.isRequired,
        pricing: PropTypes.string.isRequired,
    }),
}

export default StickyProvider

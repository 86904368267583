import React from "react";
import Layout from "../../layout/layout.component";
import {graphql} from "gatsby";
import PropTypes from "prop-types";
import SEO from "../../partials/seo/seo.component";
import PostHeading from "../../partials/post-heading/PostHeading.component";
import Container from "react-bootstrap/Container";
import ProviderInfo from "../../partials/provider-info/ProviderInfo.component";
import Row from "react-bootstrap/Row";
import RelatedReads from "../../partials/related-reads/RelatedReads.component";
import CommentsLayout from "../../partials/comments/layout.component";
import Sidebar from "../../partials/sidebar/sidebar.component";
import TableOfContents from "../../partials/table-of-contents/table-of-contents.component";
import StickyProvider from "../../partials/sticky-provider/StickyProvider.component";

const ReviewPost = (props) => {
    const review = props.data.wordpressPost,
        {schema} = props.pageContext,
        reviewProvider = JSON.parse(review.review_provider),
        rating = review.rating ? JSON.parse(review.rating) : '',
        childPosts = review.child_posts ? JSON.parse(review.child_posts) :'',
        comments = props.data.allWordpressWpComments,
        providerShortcodeLength = Object.keys(reviewProvider).length;

    return (
        <Layout whiteLayout={true}>
            {providerShortcodeLength > 0 && <StickyProvider data={reviewProvider}/>}
            <SEO data={schema}/>
            <Container>
                <div id="provider-card-wrapper">
                    <PostHeading title={review.title} date={review.small_date} text={review.information_under_title} className="post-heading-review"/>
                    {rating &&
                        <ProviderInfo rating={rating} provider={reviewProvider}/>
                    }
                </div>
                <div className="provider-logo-wrapper d-none d-lg-block">
                    <img data-src={reviewProvider.image} className="provider-logo lazyload" alt="provider-logo"/>
                </div>
                <Row className="justify-content-center">
                    <Sidebar>
                        <TableOfContents isTextAlignedRight={false} isDefaultLayout={true} elements={review.toc_elements}/>
                    </Sidebar>
                    <div id="review-post-content" className="col-12 col-md-8 col-lg-6 content-width" dangerouslySetInnerHTML={{__html: review.content}}></div>
                </Row>
                <Row className="justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6 content-width">
                        {childPosts && Array.isArray(childPosts) && childPosts.length > 0 &&
                        <RelatedReads data={childPosts} />
                        }
                        <CommentsLayout comments={comments} postId={review.wordpress_id} isDefaultLayout={false}/>
                    </div>
                </Row>
            </Container>
        </Layout>
    );
};

ReviewPost.propTypes = {
    pageContext: PropTypes.shape({
        schema: PropTypes.object.isRequired,
        id: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        wordpressPost: PropTypes.object.isRequired
    }),
};

export default ReviewPost

export const pageQuery = graphql`
    query($id: String, $comment_parent_id: Int!) {
        wordpressPost(id: { eq: $id }) {
            title
            content
            front_template
            small_date
            information_under_title
            rating
            review_provider
            child_posts
            wordpress_id
            toc_elements
        }
      allWordpressWpComments(filter: {post: {eq: $comment_parent_id}, status: {eq: "approved"}, wordpress_parent: {eq: 0}}) {
            nodes {
              wordpress_id
              author_name
              author_email
              content
              link
              date_created
              children_count
              upvote_count
              wordpress_parent
              comment_like_ip_addresses
              children_comments {
                comment_ID
                comment_author
                comment_date
                comment_content
                comment_approved
                user_id
                upvote_count
                wordpress_id
                comment_like_ip_addresses
              }
            }
            totalCount
        }
    }
`;

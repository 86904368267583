import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";

const ProviderInfo = (props) => {
    let tierClass = `tier-${props.rating.tier.toLowerCase()}`;
    return (
        <Row className="provider-info-wrapper">
            <div className="provider-info-image-wrapper col-12 d-lg-none">
                <img data-src={props.provider.image} className="provider-info-image lazyload" alt="provider-logo"/>
            </div>
            <div className={tierClass + " d-md-none col-8 mx-auto provider-tier-card"}>
                <p className="tier-rating">{props.rating.tier}</p>
                <p className="tier-text">{props.rating.rating}</p>
            </div>
            <div className="d-none d-md-flex provider-info-rating-wrapper col-8 col-md-12 col-lg-3 order-1 order-lg-0">
                <p className="provider-info-rating">{props.rating.tier}</p>
                <p className="provider-info-rating-text">{props.rating.rating}</p>
            </div>
            <div className="provider-info-speed-wrapper col-3 col-md-4 col-lg-3">
                <p className="provider-info-title-wrapper">
                   <svg className="provider-info-icon">
                       <use xlinkHref="/images/provider-info.svg#speed"/>
                   </svg>
                    <span className="provider-info-title">SPEED — {props.provider.speed}ms</span>
                </p>
                <p className="provider-info-text">{props.rating.speedText}</p>
            </div>
            <div className="provider-info-uptime-wrapper col-3 col-md-4 col-lg-3">
                <p className="provider-info-title-wrapper">
                    <svg className="provider-info-icon">
                        <use xlinkHref="/images/provider-info.svg#uptime"/>
                    </svg>
                    <span className="provider-info-title">UPTIME — {props.provider.live_uptime}%</span>
                </p>
                <p className="provider-info-text">{props.rating.uptimeText}</p>
            </div>
            <div className="provider-info-pricing-wrapper col-3 col-md-4 col-lg-3">
                <p className="provider-info-title-wrapper">
                    <svg className="provider-info-icon">
                        <use xlinkHref="/images/provider-info.svg#price"/>
                    </svg>
                    <span className="provider-info-title">PRICING — {props.provider.pricing}/10</span>
                </p>
                <p className="provider-info-text">{props.rating.priceText}</p>
            </div>
        </Row>
    );
};

ProviderInfo.propTypes = {
    rating: PropTypes.shape({
        tier: PropTypes.string.isRequired,
        priceText: PropTypes.string.isRequired,
        uptimeText: PropTypes.string.isRequired,
        speedText: PropTypes.string.isRequired,
        rating: PropTypes.string.isRequired,
    }),
    provider: PropTypes.shape({
        pricing: PropTypes.string.isRequired,
        live_uptime: PropTypes.string.isRequired,
        speed: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    }),
};

export default ProviderInfo
